<template>
  <Navbar />
  <router-view />
  <Footer class="app_footer" />
</template>

<script>
import Navbar from "@/component/layout/Navbar.vue";
import Footer from "@/component/layout/Footer.vue";
import { onMounted } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    Navbar,
    Footer,
  },
  setup() {
    const store = useStore();

    onMounted(async () => {
      await store.dispatch("updateWorksFmaf");
      await store.dispatch("updateWorkLocations");
      await store.dispatch("updateDataIsReady");
    });
  },
};
</script>

<style lang="scss">
@import "@/assets/css/style.scss";

#app {
  width: 100%;
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.app_footer {
  flex: 0;
}
</style>

import preloader from "monoame-preloader";

let imgUrls = [];

// imgUrls.push(require(`@/assets/images/explore_${sight.name}.png`))
// console.log(imgUrls)

// Preload
preloader
  .load(imgUrls)
  .then(() => {
    console.log("image all preloaded!");
  })
  .catch(() => {
    console.log("image preload fail.");
  });

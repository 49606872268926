import { createStore } from "vuex";
import { getWorks, getWork, getWorkLocations } from "@/api/work"
import { getWorkSlug, sortWorks } from "@/utils/utils"

export default createStore({
  state: {
    browser: {
      name: "",
      version: "",
      worksFmaf: [],
      workLocations: [],
      dataIsReady: false,
      currentWorkType: ""
    },
    isFirstVisit: true,
  },
  mutations: {
    setIsFirstVisit(state, data){
      state.isFirstVisit = data
    },
    setBrowser(state, data) {
      state.browser = data
    },
    setWorksFmaf(state, data){
      state.worksFmaf = data
    },
    setWorkLocations(state, data){
      state.workLocations = data
    },
    setDataIsReady(state){
      state.dataIsReady = true
    },
    setCurrentWorkType(state, data){
      state.currentWorkType = data
    }
  },
  // w.work_en.title.toLowerCase().replace(/-|:|\.| /g,'-')
  actions: {
    async updateWorksFmaf(){
      let works = await getWorks()
      works = works.sort(sortWorks).map(w => {
        // console.log(w.location.name_en)
        return {
          ...w,
          slug: getWorkSlug(w.work_en.title)
        }
      })
      this.commit('setWorksFmaf', works)
    },
    async updateWorkLocations(){
      let workLocations = await getWorkLocations()
      workLocations.forEach(l => {
        l.works = this.state.worksFmaf.filter(w => w.location.work_location_id === l.work_location_id)
      })
      this.commit('setWorkLocations', workLocations)
    },
    updateDataIsReady(){
      this.commit('setDataIsReady')
    },
    updateCurrentWorkType(store, type){
      this.commit('setCurrentWorkType', type)
    },
    updateIsFirstVisit(store, type){
      this.commit('setIsFirstVisit', type)
    },
  },
  modules: {}
});

const shared = {
    'shared.artist': '藝術家',
    'shared.artwork': '作品',
    'shared.futureLabVision': 'FUTURE VISION LAB',
    'shared.event': '活動',
    'shared.about': '關於展覽',
    'shared.showMore': '顯示更多',
    'shared.readMore': '閱讀更多',
    'shared.all': '全部',
    'shared.CLAB': 'C-LAB 未來媒體藝術節',
}

const artistRoom = {
    // 'artistRoom.exhTitle': '以「未來」為命題，邀請國內外23組藝術家、24件作品。',
    'artistRoom.exhTitle': '',
    'artistRoom.nonExhTitle': '',
    // 'artistRoom.nonExhTitle': '「FUTURE VISION LAB 2021」今年持續進化戶外的穹頂構築，規劃了14檔作品展演和1場講座，邀請國內外藝術家製作沉浸影音與跨域的作品。',
}

const about = {
    'about.subtitle': '虛實並進想像未來',
    'about.content': '臺灣當代文化實驗場年度大展「C-LAB未來媒體藝術節」於10月8日至11月28日登場，由C-LAB策展人吳達坤與客座策展人蔡宏賢共同合作，以「未來」為命題，邀請國內外23組藝術家、24件作品，並舉辦系列講座與工作坊，串連科技媒體實驗平台的未來視覺展演計畫「FUTURE VISION LAB」，企圖構築媒體藝術的未來想像。C-LAB也首度推出線上展覽平台「Unzip C-LAB：展演匯流站」，以線上、線下虛實並進的展覽、工作坊、講座形式。誠摯邀請您，與我們一起探索未來。',
}

const fvl = {
    'fvl.schedule': '展演日程',
    'fvl.about': '計畫介紹',
    'fvl.about.paragrapph1': '臺灣當代文化實驗場（C-LAB）2020年成立「科技媒體實驗平台」，著重「媒體實驗創新」與「社會鏈結」，以跨域、共創、協作的方式，打造文化與科技的實驗平台，整合臺灣文化與科技的資源，媒合文化與科技領域共創，其中「未來視覺實驗室」以探索科技媒體的視覺極限為實驗目標，並以臺灣光電技術與產業為基礎，輔以影像的運算、創作與軟硬體整合，勾勒未來視覺的跨域願景。',
    'fvl.about.paragrapph2': '科技媒體實驗平台的實驗展演計畫「FUTURE VISION LAB」，於2020年與國立陽明交通大學建築研究所合作，透過數位建築概念，打造直徑12公尺的戶外穹頂構築（DOME）展演空間。這座巨大半球形的全沉浸式影音體驗空間，涉及球形曲面投影的校正、融接、對位、播放控制與影像前製等多項複雜技術，期待透過技術讓觀眾的感官能完全沉浸其中。',
    'fvl.about.paragrapph3': '「FUTURE VISION LAB 2021」今年持續進化戶外的穹頂構築，由蔡奇宏擔任計畫主持人，規劃了14檔作品展演和1場講座，邀請國內外藝術家製作沉浸影音與跨域的作品，更首度開放徵件，徵選出多位新銳數位媒體創作者的作品。此計畫也與國立臺北藝術大學新媒體藝術學系和實踐大學媒體傳達設計學系產學合作，舉辦工作坊和聯合成果展，並推出適合大眾與親子的天文觀星講座等活動。「FUTURE VISION LAB」期待透過不同合作，結合多元的展演內容，帶來全新的感官體驗。',
    'fvl.principle.title': '計畫主持人｜蔡奇宏',
    'fvl.priciple.content': '從資訊背景跨入新媒體藝術領域，畢業於臺北藝術大學新媒體藝術研究所，共同創辦沃手工作藝術團隊，現為臺灣當代文化實驗場科技媒體實驗平台FUTURE VISION LAB技術總監。主要致力於將類比與數位以不同方式轉譯演算。擅長軟硬體整合、系統設計、演算生成、聲音裝置與燈光裝置等，挑戰各種技術形式來探索不同媒材之間的可能性。',
}

const activity = {
    'activity.october': '十月',
    'activity.november': '十一月',
    'activity.all': '總覽',
    'activity.showMore': '點選左側，了解更多活動資訊！',
    'activity.lecturer': '講者',
    'activity.date': '日期',
    'activity.time': '時間',
    'activity.venue': '地點',
    'activity.booking': '報名網址',
    'activity.final': '成果展示',
    'activity.streaming': '直播網址',
    'activity.intro': '活動簡介',
    'activity.bio': '講者簡介',
}

const artwork = {
    'artwork.back': '作品列表',
    'artwork.year': '年份',
    'artwork.media': '媒材',
    'artwork.dimensions': '尺寸/影片長度',
    'artwork.credit': '所有權/其他說明',
    'artwork.otherWorks': '藝術家其他作品',
    'artwork.like': '猜你也喜歡',
    'artwork.time': '場次時間',
}

const footer = {
    'footer.supervisor':' 指導單位',
    'footer.organizer':' 主辦單位',
    'footer.executor':' 執行單位',
    'footer.collaborator':' 合作單位',
    'footer.sponsor':' 贊助單位',
    'footer.CLAB': 'C-LAB未來媒體藝術節',
    'footer.copyright': '本網站圖文資料未經授權請勿使用',
    'footer.followUs': '追蹤我們',
    'footer.privacy': '隱私權與資訊安全宣告',
}

export default {
    ...shared,
    ...artistRoom,
    ...about,
    ...fvl,
    ...activity,
    ...artwork,
    ...footer,
}
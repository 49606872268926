import request from "@/utils/request";
import mitt from "mitt";
export const emitter = mitt();

export function deepClone(obj, hash = new WeakMap()) {
    if (Object(obj) !== obj) return obj; // primitives
    if (hash.has(obj)) return hash.get(obj); // cyclic reference
    const result = obj instanceof Set ? new Set(obj) // See note about this!
        :
        obj instanceof Map ? new Map(Array.from(obj, ([key, val]) => [key, deepClone(val, hash)])) :
        obj instanceof Date ? new Date(obj) :
        obj instanceof RegExp ? new RegExp(obj.source, obj.flags) :
        obj.constructor ? new obj.constructor() :
        Object.create(null);
    hash.set(obj, result);
    return Object.assign(result, ...Object.keys(obj).map(
        key => ({
            [key]: deepClone(obj[key], hash)
        })));
}


export function getYTImg(videoUrl) {
    if(videoUrl.includes('watch')){
        const videoCode = videoUrl.split('https://www.youtube.com/watch?v=')[1]
        return `https://i.ytimg.com/vi/${videoCode}/maxresdefault.jpg`
    }
    try{
        const videoCode = videoUrl.split('/')[3]
        return `https://i.ytimg.com/vi/${videoCode}/maxresdefault.jpg`
    }catch(e){
        console.log(e)
        return ''
    }
}

export function getPhotoUrl(photo_path) {
    return process.env.VUE_APP_BASE_API + photo_path
}

export function getWorkSlug(workEnName) {
    const slugMap = {
        "Crystal Seeding": "crystal-seeding",
        "Inverso Mundus": "inverso-mundus",
        "The Weight of Things": "the-weight-of-things",
        "Future Moshing, Datamosh !": "future-moshing-dataMosh",
        "Sugar Foot": "sugar-foot",
        "Sinofuturism（1839-2046 AD）": "sinofuturism-1839-2046-ad",
        "Copies of Illusion": "copies-of-illusion",
        "Tidal Variations (2021)": "tidal-variations",
        "Battle City Trilogy": "battle-city-trilogy",
        "The Ways of Folding Space & Flying": "the-ways-of-folding-space-flying",
        "Encoding/decoding": "encoding-decoding",
        "Meandering River": "meandering-river",
        "iFaceDQ": "iface-dq",
        "Kepler’s Dream": "keplers-dream",
        "SoftMachine": "soft-machine",
        "TFN—Technological Finding Netbot": "technological-finding-netbot",
        "Space Type Generator": "space-type-generator",
        "The Glass Room (Misinformation Edition) ": "the-glass-room",
        "Big Dada: Public Faces": "big-dada-public-faces",
        "Memory War": "memory-war",
        "Weightless Plan—New Normal": "weightless-plan-new-normal",
        "HETEROTOPIA": "heterotopia",
        "Twin Temples, Moniang’s Hair and Heavenly Hong": "twin-temples-moniangs-hair-and-heavenly-hong",
    }
    let slug = slugMap[workEnName]
    if(!slug) slug = workEnName.toLowerCase().replace(/-|:|\.| /g,'-')
    return slug
}

export function sortWorks(a, b) {
    const sortOrder = [
        '0f1cd1a3-02c5-4667-9de7-968f5830f00c',
        'd9cb1939-fe73-4509-b5b8-f0d06a2b63b1',
        '3f60ae5a-cf8b-4eff-9762-5be00ee75c36',
        '5bc68d5b-960a-4a13-838d-f344c5ee2930',
        '6ac46d5e-1b4f-4c24-9658-e42339c0d91d',
        'a7ba19a2-c430-47bd-909e-474e9c07e90c',
        '72a9f399-be00-4a07-93ef-6ea2c9a0f685',
        '0b01d362-e3ae-43ef-98d5-7e94478d1fe8',
        '04fc0ae5-c2ad-4efb-be54-031eb6167484',
        '9fb4488d-f495-419a-b1c2-e7b9416db5db',
        '952e9883-fbbd-4e50-a582-15a34877ed0f',
        '5a3c4cab-f3ec-46e5-982f-32e49ee9599e',
        '9bbabf75-437b-44ad-be1b-1b0ab6a99a5e',
        'e47adbbf-c2ed-4e04-a2cb-6324869e7273',
        'd3239088-40a2-4963-a0e4-e1b39ff1dc84',
        '82096081-d936-4366-9565-310bfd0bf5f6',
        'f7bc1462-9507-411a-8215-f97d438ac223',
        '984a9df0-c9b8-448f-bb1c-075220f05412',
        '527c7177-a7f7-48a9-9936-6fc4e3560c92',
        '5bccfe12-e463-43e0-81d8-f47f9e435300',
        'eeaa534c-a761-4e2e-83f5-2e09276df069',
        '65ea43a0-fdcf-494f-8f4c-f154c3157004',
        '58d15c21-7348-4e16-9de9-61f2f9ce9b6a',
        '6419c162-e95d-4559-9fd5-44bba0c6ac43',
        '1461cc69-123b-43e3-942b-b145706d0799',
        '6034710e-bb63-4dd9-8ae5-6061008753a1',
        '3e41f2a9-6c5f-4a5d-b15d-100a93868e26',
        'a584078a-0db7-44af-a365-e94f8f11af30',
        '50b7bab1-0959-46f1-bc9b-c8f427b8a8c6',
        'fc97911e-9b80-42cc-ae5b-fba4c6b6e174',
    ]
    
    return sortOrder.indexOf(a.work_id) - sortOrder.indexOf(b.work_id);
}


export function getDistance(pos1, pos2) {
    const diffX = pos1.x-pos2.x
    const diffY = pos1.y-pos2.y
    const dist = Math.sqrt(diffX*diffX, diffY*diffY)
    return dist
}

export function lerp(start, end, amt){
    return (1-amt)*start+amt*end
}
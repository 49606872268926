import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Index"),
  },
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/Index"),
  },
  {
    path: "/artists-room",
    name: "artists-room",
    component: () => import("@/views/ArtistsRoom"),
  },
  {
    path: "/activity",
    name: "activity",
    component: () => import("@/views/Activity"),
  },
  {
    path: "/fmaf",
    name: "fmaf",
    component: () => import("@/views/Fmaf"),
  },
  {
    path: "/fvl",
    name: "fvl",
    component: () => import("@/views/Fvl"),
  },
  {
    path: "/artworks/:slug",
    name: "artwork",
    component: () => import("@/views/Artwork"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/About"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.afterEach((to, from) => {
  if (from.path !== to.path) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
});

export default router;

const shared = {
    'shared.artist': 'Artists',
    'shared.artwork': 'Artwork',
    'shared.futureLabVision': 'FUTURE VISION LAB',
    'shared.event': 'Event',
    'shared.about': 'About',
    'shared.showMore': 'More...',
    'shared.readMore': 'More...',
    'shared.all': 'All',
    'shared.CLAB': 'C-LAB Future Media Arts Festival',
}

const artistRoom = {
    // 'artistRoom.exhTitle': 'On this future-themed event, it includes 24 works produced by 23 individuals and groups of artists from Taiwan and abroad.',
    'artistRoom.exhTitle': '',
    'artistRoom.nonExhTitle': '',
    // 'artistRoom.nonExhTitle': 'This year, FUTURE VISION LAB 2021 includes fourteen performances and one lecture, with international and local artists creating immersive cross-disciplinary audiovisual performances especially for this dome.',
}

const about = {
    'about.subtitle': 'Imagine the Future in Real and Virtual Spaces',
    'about.content': 'The C-LAB’s annual grand event “Future Media Arts Festival” is going to be on view from 8 October to 28 November 2021. Co-curated by our in-house curator Dar-Kuen Wu and guest curator Escher Tsai, this festival revolves around the proposition of “future” and invites 23 artists/artist groups from home and abroad to present 24 pieces of work in the company of a series of lectures and workshops. Besides, the C-LAB Technology Media Platform’s project “FUTURE VISION LAB” will be presented in parallel with the festival. We expect to stimulate active imagination about the future of media art. C-LAB will also launch its first online platform “Unzip C-LAB” during the festival. Proceeding in the form of online/offline exhibitions, workshops, and lectures. We cordially invite you to explore the future with us.',
}

const fvl = {
    'fvl.schedule': 'Schedule',
    'fvl.about': 'About FVL',
    
    'fvl.about.paragrapph1': 'In 2020, the Taiwan Contemporary Culture Lab (C-LAB) established the Technology Media Platform which emphasizes innovative media experimentation and social connections. Its purpose is to construct a cultural and technological experimental platform based on cross-disciplinary collaboration, co-creation, and coordination by integrating Taiwan’s cultural and technological resources. The FUTURE VISION LAB project, initiated by the Technology Media Platform, explores the visual limits of technology media. With sound and image algorithms, creative works, and the integration of hardware and software, an attempt is made to outline a cross-disciplinary vision for the enhancement of visual effects.',
    'fvl.about.paragrapph2': 'Based on the concept of digital architecture, a dome measuring 12 meters in diameter and 6 meters in height was constructed with the Graduate Institute of Architecture at National Yang Ming Chiao Tung University for FUTURE VISION LAB in 2020. To create a fully immersive experiential dome space involves multiple complicated techniques, such as warping, edge blending, mapping, play control, and video production. Through technology, FUTURE VISION LAB presents fully immersive audiovisual experiences.',
    'fvl.about.paragrapph3': 'This year, FUTURE VISION LAB 2021, leaded by the principal TSAI Chi- Hung, includes fourteen performances and one lecture, with international and local artists creating immersive cross-disciplinary audiovisual performances especially for this dome. In addition, there are eight emerging new media artists’ works selected via an open call process, a joint presentation hosted by students from the Department of New Media Art at Taipei National University of the Arts and the Department of Communications Design at Shih Chien University, and stargazing workshops that are suited to families. Through collaborations, FUTURE VISION LAB presents a diversity of performances and visual experiences to the public.',
    'fvl.principle.title': 'Principal｜TSAI Chi-Hung',
    'fvl.priciple.content': 'Inspired by some professionals and deciding to step into the field of new media art from the information engineering background, TSAI Chi-Hung obtained an MA in New Media Art, Taipei National University of Arts, and started to create art in different formats of “information.” He is the co-founder of WINNOWORK, and is the technical director in FUTURE VISION LAB of C-LAB Technology Media Platform. Dedicated to creating the different combinations of analog and digital signal, he specializes in software/hardware system integration, system design, generative art, audio/visual installations, and explores any possibility between different media via different kinds of technical formats.',
}

const activity = {
    'activity.october': 'October',
    'activity.november': 'November',
    'activity.all': 'All',
    'activity.showMore': 'Click for More >>',
    'activity.lecturer': 'Lecturer',
    'activity.date': 'Date',
    'activity.time': 'Time',
    'activity.venue': 'Venue',
    'activity.booking': 'Booking',
    'activity.final': 'Fianl Project',
    'activity.streaming': 'Streaming',
    'activity.intro': 'Introduction',
    'activity.bio': 'Biographies',
}

const artwork = {
    'artwork.back': 'Back to list',
    'artwork.year': 'Year',
    'artwork.media': 'Media',
    'artwork.dimensions': 'Dimensions / length',
    'artwork.credit': 'Credit / Notes',
    'artwork.otherWorks': 'Other works',
    'artwork.like': 'You might also like',
    'artwork.time': 'Time',
}

const footer = {
    'footer.supervisor':' Supervisor',
    'footer.organizer':' Organizer',
    'footer.executor':' Executor',
    'footer.collaborator':' Collaborator',
    'footer.sponsor':' Sponsor',
    'footer.copyright': 'All materials not authorized may not be redirected or for other usages.',
    'footer.followUs': 'Follow Us',
    'footer.privacy': 'Privacy Policy',
}

export default {
    ...shared,
    ...artistRoom,
    ...about,
    ...fvl,
    ...activity,
    ...artwork,
    ...footer,
}
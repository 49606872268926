<template lang="pug">
nav.navbar.navbar-expand-lg.fixed-top.justify-content-between(
  v-if="currentRoute !== 'index' && $route.name !== 'Home'"
  ref='nav'
)
  img.navbar__logo.me-auto(:src="require(`@/assets/images/navbar-logo.svg`)" @click="linkToPage('/')")
  .navbar__line
  #navbarBtn.navbar-toggler(type='button' data-bs-toggle='collapse' data-bs-target='#navbarContent' aria-controls='navbarContent' aria-expanded='false' aria-label='Toggle navigation')
    .navbar__icon
      div
  #navbarContent.collapse.navbar-collapse.align-items-stretch
    ul.navbar-nav
      router-link.navbar__link( :to="{ name: 'artists-room' }")
        li.navbar__item(data-bs-toggle="collapse" data-bs-target='.navbar-collapse.show' :class="{'navbar__item--active': currentRoute === 'artists-room'}") {{ $t('shared.artist') }}
      router-link.navbar__link( :to="{ name: 'fmaf' }")
        li.navbar__item(data-bs-toggle="collapse" data-bs-target='.navbar-collapse.show' :class="{'navbar__item--active': currentRoute === 'fmaf'|| (currentRoute === 'artwork' && $store.state.currentWorkType === 'fmaf')}") {{ $t('shared.artwork') }}
      router-link.navbar__link( :to="{ name: 'fvl' }")
        li.navbar__item(data-bs-toggle="collapse" data-bs-target='.navbar-collapse.show' :class="{'navbar__item--active': currentRoute === 'fvl' || (currentRoute === 'artwork' && $store.state.currentWorkType === 'fvl')}") {{ $t('shared.futureLabVision') }}
      router-link.navbar__link( :to="{ name: 'activity' }")
        li.navbar__item(data-bs-toggle="collapse" data-bs-target='.navbar-collapse.show' :class="{'navbar__item--active': currentRoute === 'activity'}") {{ $t('shared.event') }}
      router-link.navbar__link( :to="{ name: 'about' }")
        li.navbar__item(data-bs-toggle="collapse" data-bs-target='.navbar-collapse.show' :class="{'navbar__item--active': currentRoute === 'about'}") {{ $t('shared.about') }}
      li.navbar__lang.d-lg-flex.flex-row.align-items-center
        img.navbar__lang__img(:src="require(`@/assets/images/navbar-global.svg`)")
        .navbar__lang__item(
          :class="{'navbar__lang__item--active': $i18n.locale === 'zh'}"
          @click="changeLocale('zh')"
        ) 繁中
        .navbar__lang__item(
          :class="{'navbar__lang__item--active': $i18n.locale === 'en'}"
          @click="changeLocale('en')"
        ) En
</template>

<script>
import { useRoute } from "vue-router";
import { computed } from "vue";

export default {
  name: "Navbar",
  data() {
    return {
      isNavOpen: false,
      bsCollapse: null,
    };
  },
  components: {},
   mounted() {
    //  console.log(this.$store.state)
    // const menuToggle = document.getElementById('navbarContent')
    // const bsCollapse = new Collapse(menuToggle);
    // menuToggle.addEventListener('show.bs.collapse', function () {
    //   this.isNavOpen = true;
    // })
    // menuToggle.addEventListener('hide.bs.collapse', function () {
    //   this.isNavOpen = false;
    // })
  },
  // beforeDestroy() {
  //   document.removeEventListener("click", this.bodyCloseMenus);
  // },
  methods: {
    linkToPage(routerPath) {
      this.$router.push({ path: routerPath });
    },
    changeLocale(locale) {
      localStorage.setItem("lang", locale);
      this.$i18n.locale = locale;
    }
    // bodyCloseMenus(e) {
    //   const menuToggle = document.getElementById('navbarContent')
    //   const menuToggleBtn = document.getElementById('navbarBtn')
    //   const bsCollapse = new Collapse(menuToggle)
    //   let self = this;
    //   console.log('this.isNavOpen',this.isNavOpen)
    //   if (this.$refs.nav && !this.$refs.nav.contains(e.target)) {
    //     console.log("AAA")
    //     if(this.isNavOpen == true){
    //     console.log("BBB")

    //       bsCollapse.toggle()
    //     }
    //   }
    //   console.log('menuToggleBtn.contains(e.target)',menuToggleBtn.contains(e.target))
    //   console.log('this.isNavOpen',this.isNavOpen)
    //   if(menuToggleBtn.contains(e.target)){
    //     if(this.isNavOpen == true){
    //       bsCollapse.toggle()
    //     }
    //   }
    // }
  },
  setup() {
    const currentRoute = computed(() => {
      return useRoute().name;
    });
    return { currentRoute };
  },
};
</script>

<style lang="sass" scoped>
.navbar-nav
  margin-left: auto
  width: 100%
  justify-content: flex-end

.navbar-toggler
  flex-direction: column
  justify-content: center
  -webkit-appearance: none
  appearance: none
  +rwd-md
    display: flex
    padding: 4px 22px 4px 22px
.navbar
  background: #F8F8F8
  padding: 0
  border-bottom: solid 1px #000000
  align-items: normal
  border-bottom: solid 1px #000
  +rwd-md
    background: #fff
  &__line
    display: none
    width: 1px
    height: 58px
    background-color: #000
    margin: 2.5px 0
    +rwd-md
      display: block
  &__logo
    padding: 4px 0 4px 11px
    cursor: pointer
    +rwd-md
      padding: 14px 0 12px 14px
      max-width: 142px

  &__link
    display: flex
    align-items: center
    cursor: pointer
    align-self: stretch
    width: 100%
    max-width: 147px
    color: #000
    text-decoration: none
    +rwd-md
      max-width: none

  &__item
    display: flex
    align-items: center
    cursor: pointer
    align-self: stretch
    width: 100%
    // max-width: 147px
    padding: 0 6px
    color: #000
    text-decoration: none
    &:hover
      color: #fff
      background-color: #000000
    a
      color: #000
      text-decoration: none
    +rwd-md
      padding: 27px
    &--active
      color: #fff
      background-color: #000000
      a
        color: #fff

  &__lang
    cursor: pointer
    padding-right: 8px
    width: 100%
    max-width: 105px
    padding-left: 11px
    +rwd-md
      display: none
    &__img
      margin-right: 4px
    &__item
      // font-weight: bold
      line-height: 12px
      margin-right: 4px
      padding-right: 8px
      &:hover
        background-color: #000000
        color: white
      &--active
        background-color: #000000
        color: white

.navbar__icon
    // border-bottom: solid 3px #000000
    // width: 26px
    // height: 1px
    width: 26px
.navbar__icon:after,
.navbar__icon:before,
.navbar__icon div
  background-color: #000
  content: ''
  display: block
  height: 2px
  margin: 6px 0
  transition: all .2s ease-in-out
.navbar-toggler[aria-expanded="true"]
  .navbar__icon:before
    transform: translateY(7px) rotate(135deg)
  .navbar__icon:after
    transform: translateY(-9px) rotate(-135deg)
  .navbar__icon div
    transform: scale(0)
</style>

import request from "@/utils/request";

/**
 * Get all works with search features.
 *
 * @param {object} params
 * @param {number} [params.limit]
 * @param {string} [params.search] - search category and work name
 */
export function getWorks(params) {
  return request({
    url: "api/v1/works",
    method: "GET",
    params,
  });
}

/**
 * Get one work.
 *
 * @param {string} work_id
 */
export function getWork(work_id) {
  return request({
    url: `api/v1/works/${work_id}`,
    method: "GET",
  });
}

/**
 * Get all work locations.
 */
export function getWorkLocations() {
  return request({
    url: `api/v1/works-locations/`,
    method: "GET",
  });
}

<template lang="pug">
.footer(v-if="currentRoute !== 'Home' && currentRoute !== 'index' && currentRoute !== 'fvl' && currentRoute !== 'fmaf'")
  .d-flex.flex-column.justify-content-center.align-items-center.align-items-lg-start.flex-lg-row
    .footer__item.d-flex.flex-column.justify-content-center.justify-content-lg-start.align-items-lg-start.align-items-center.me-82-0
      .footer__item__title {{ $t('footer.supervisor') }}
      img.img-w117.img-mt30-20.footer__item__img.footer__item__img--last(:src="require(`@/assets/images/文化部.png`)")
    .footer__item.d-flex.flex-column.justify-content-center.justify-content-lg-start.align-items-lg-start.align-items-center.me-82-0
      .footer__item__title {{ $t('footer.organizer') }}
      img.img-w117.img-mt28-18.footer__item__img.footer__item__img--last(:src="require(`@/assets/images/clab.png`)")
    .footer__item.d-flex.flex-column.justify-content-center.justify-content-lg-start.align-items-lg-start.align-items-center.me-82-0
      .footer__item__title {{ $t('footer.executor') }}
      img.img-w117.img-mt28-18.footer__item__img.footer__item__img--last(:src="require(`@/assets/images/科媒.png`)")
    .footer__item.footer__item--last.d-flex.flex-column.justify-content-center.justify-content-lg-start.align-items-lg-start.align-items-center
      .footer__item__title {{ $t('footer.sponsor') }}
      .footer__item__row.d-flex.img-mt14-4.justify-content-center.align-items-center.pt-md-3
        img.img-w117.footer__item__img.me-3-0.img-mt0-30(:src="require(`@/assets/images/義丞有限公司.png`)")
        img.img-w117.footer__item__img.footer__item__img--last.img-mt0-30(:src="require(`@/assets/images/奧圖碼.png`)")
  .d-flex.flex-column.justify-content-center.align-items-center.align-items-lg-start.flex-lg-row.mt-20-0
    .footer__item.mt-33.d-flex.flex-column.justify-content-center.justify-content-lg-start.align-items-lg-start.align-items-center
      .footer__item__title {{ $t('footer.collaborator') }}
      .footer__item.d-flex.img-mt14-4.justify-content-center.align-items-center.column-3-row
        img.img-w93-w73.footer__item__img.me-3-0.img-mt0-30.column-3-item(:src="require(`@/assets/images/AND臺灣藝術與科技中心.png`)")
        img.img-w93-w73.footer__item__img.me-3-0.img-mt0-30.column-3-item(:src="require(`@/assets/images/DimensionPlus.png`)")
        img.img-w93-w73.footer__item__img.me-3-0.img-mt0-30.column-3-item(:src="require(`@/assets/images/微波.png`)")
        img.img-w93-w73.footer__item__img.me-3-0.img-mt0-30.column-3-item(:src="require(`@/assets/images/文化部科技藝術實驗創新及輔導推動計畫.png`)")
        img.img-w93-w73.footer__item__img.me-3-0.img-mt0-30.column-3-item(:src="require(`@/assets/images/上銀科技.png`)")
        img.img-w93-w73.footer__item__img.me-3-0.img-mt0-30.column-3-item(:src="require(`@/assets/images/國立臺北藝術大學新媒體藝術學系.png`)")
        img.img-w93-w73.footer__item__img.me-3-0.img-mt0-30.column-3-item(:src="require(`@/assets/images/國網中心.png`)")
        img.img-w93-w73.footer__item__img.img-mt0-30.column-3-item(:src="require(`@/assets/images/usc.svg`)")
  .d-flex.flex-column.flex-lg-row.justify-content-center.align-items-center
    .footer__copyright {{ $t('shared.CLAB') }}
    .footer__copyright.d-none.d-lg-block |
    a(href='https://clab.org.tw/privacy' target='_blank').footer__copyright.footer__link {{ $t('footer.privacy') }}
    .footer__copyright.d-none.d-lg-block |
    .d-flex.align-items-center
      .footer__copyright.pe-3 {{ $t('footer.followUs') }}
      img.footer__item__icon(:src="require(`@/assets/images/footer-facebook.svg`)")
      img.footer__item__icon.footer__item__icon--last(:src="require(`@/assets/images/footer-instagram.svg`)")
</template>

<script>
import { useRoute } from "vue-router";
import { computed } from "vue";

export default {
  name: "Footer",
  components: {},
  setup() {
    const currentRoute = computed(() => {
      return useRoute().name;
    });

    return { currentRoute };
  },
};
</script>

<style lang="sass" scoped>
.footer
  background-color: #000000
  padding: 44px 20px
  width: 100%
  // position: absolute
  bottom: 0
  +rwd-md
    padding: 32px 0 41px

  &__item

    +rwd-md
      margin-right: 0
      margin-bottom: 45px

    &--last
      margin-right: 0

    &__title
      color: #969696
      font-size: 12px

    &__icon
      cursor: pointer
      margin-right: 9px

      &--last
        margin-right: 0

    &__img
      align-self: center

      &--last
        margin-right: 0

    &__text
      font-weight: 900
      color: #fff
      text-align: center

    &__row
      +rwd-md
        flex-direction: column

  &__copyright
    display: inline-block
    font-family: "Noto Sans TC", Arial, Helvetica, sans-serif
    color: #969696
    font-size: 12px
    padding-right: 24px
    letter-spacing: 0.666667px
    +rwd-md
      padding-right: 0
      margin-bottom: 8px

    &--last
      padding-right: 0
      
  &__link
    text-decoration: none

.img-w100
  width: 100px
  max-width: 100%
  +rwd-md
    width: 37.5%

.img-w150
  width: 150px
  max-width: 100%
  +rwd-md
    width: 40%

.img-w62
  width: 62px
  max-width: 100%
  +rwd-md
    width: 21%

.img-w117
  width: 117px
  max-width: 100%
  +rwd-md
    width: 32%

.img-w93-w73
  width: 93px
  max-width: 100%
  +rwd-md
    width: 25%

.img-mt30-20
  margin-top: 30px
  +rwd-md
    margin-top: 20px

.img-mt28-18
  margin-top: 28px
  +rwd-md
    margin-top: 18px

.img-mt14-4
  margin-top: 14px
  +rwd-md
    margin-top: 4px

.img-mt0-30
  margin-top: 0
  +rwd-md
    margin-top: 30px

.mt-33
  +rwd-md
    margin-bottom: 33px

.mt-20-0
  margin-top: 20px
  +rwd-md
    margin-top: 0

.mt-lg-38
  margin-right: 38px
  +rwd-md
    margin-right: 0

.me-82-0
  margin-right: 82px
  +rwd-md
    margin-right: 0

.me-3-0
  margin-right: 1rem
  +rwd_md
    margin-right: 0
    
.column-3-row
  +rwd_md
    flex-wrap: wrap
    margin: 0 20px 45px 20px
    
  .column-3-item
    +rwd_md
      flex: 1 0 33.3%
      padding: 0 20px
  
</style>
